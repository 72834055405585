import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import { Container } from 'theme-ui'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import WhatYouCanDo from '@solid-ui-blocks/Features/Block04'
import WhyConnect from '@solid-ui-blocks/Features/Block02'
import ConnectHero from '../../components/ConnectHero'
import Process from '@solid-ui-blocks/Features/Block03'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from '@elegantstack/gatsby-theme-flexiblocks/src/homepage/saas/_styles';

const Support = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Connect Google My Business and QuickBooks Online'
        description='Integrate Google My Business and QuickBooks Online to send automatic request reviews or customer surveys when you finish a job. Easy to set up, zero maintenance and fairly priced.'
        siteUrl='/'
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <ConnectHero content={content['hero']} />
      <Divider space='4' />
      <WhyConnect content={content['why-connect']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <WhatYouCanDo content={content['what-you-can-do']} />
      </Container>
      <Divider space='5' />
      <Process content={content['how-to-connect']} />
      <Divider space='5' />
      <Contact content={content['lower-cta']} />
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query connectQBOGMBBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/connect-qbo-gmb"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Support
