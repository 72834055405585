import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const ConnectHero = ({ content: { text, buttons, collection } }) => (
  <Container sx={{ textAlign: `center` }}>
    <Reveal effect='fadeInDown'>
      <ContentText content={text} />
    </Reveal>
    {collection && (
      <>
        <Divider />
        <Reveal effect='fadeInDown'>
          <Flex sx={{ justifyContent: `center`, flexWrap: `wrap`, m: -3 }}>
            {collection.map(({ container, images, ...props }, index) => (
              <Box
                key={`item-${index}`}
                sx={{ flexBasis: [`1/2`], p: 3 }}
              >
                  <ContentImages
                    content={{ images }}
                    loading='eager'
                    imagePosition='center'
                    imageEffect='fadeInUp'
                  />
              </Box>
            ))}
          </Flex>
        </Reveal>
      </>
    )}
    {buttons && (
      <>
        <Divider space={3} />
        <Reveal effect='fadeInUp' duration={0.7}>
          <ContentButtons content={buttons} />
        </Reveal>
      </>
    )}
  </Container>
)

export default WithDefaultContent(ConnectHero)
